import React, { useState } from "react"
import { Link } from "gatsby"

export default () => {
  const [showMenu, setShowMenu] = useState(false)
  const [subMenuFeatures, setSubMenuFeatures] = useState(false)
  const [subMenuCompany, setSubMenuCompany] = useState(false)

  const toggleShowMenu = () => {
    setShowMenu(!showMenu)
  }

  const showSubMenuFeatures = () => {
    setSubMenuFeatures(!subMenuFeatures)
  }
  const showSubMenuCompany = () => {
    setSubMenuCompany(!subMenuCompany)
  }

  return (
    <>
      <div
        className={`menu-btn ${showMenu ? "close-menu-btn" : ""}`}
        onClick={() => toggleShowMenu()}
        onKeyDown={() => toggleShowMenu()}
        role="link"
        tabIndex={0}
      >
        <i className="icon-burger"></i>
      </div>
      <div className="mobile-nav-menu">
        <ul className={`list-inline navigation ${showMenu ? "show-nav" : ""}`}>
          <li className={"navigation__item navigation__item--with-children"}>
            <button
              className="nav-link"
              onClick={() => showSubMenuFeatures()}
              onMouseEnter={() => showSubMenuFeatures()}
            >
              Features
            </button>
            <div
              className="subnav subnav--features"
              style={{ display: subMenuFeatures ? "block" : "none" }}
            >
              <ul className="list-unstyled subnav__items">
                <li className="subnav__item">
                  <Link to="/daily-rx-discounts/" className="subnav-link">
                    <span className="subnav-link__illus subnav-link__illus--deals">
                      <span className="illus__bg"></span>
                      <span className="illus__element illus__element1"></span>
                      <span className="illus__element illus__element2"></span>
                      <span className="illus__point illus__point1"></span>
                      <span className="illus__point illus__point2"></span>
                      <span className="illus__point illus__point3"></span>
                    </span>
                    <strong className="subnav-link__label">Daily Deals</strong>
                  </Link>
                </li>
                <li className="subnav__item">
                  <Link
                    to="/pharmacy-payment-management/"
                    className="subnav-link"
                  >
                    <span className="subnav-link__illus subnav-link__illus--credit">
                      <span className="illus__bg"></span>
                      <span className="illus__element"></span>
                      <span className="illus__point illus__point1"></span>
                      <span className="illus__point illus__point2"></span>
                      <span className="illus__point illus__point3"></span>
                    </span>
                    <strong className="subnav-link__label">
                      Online Payments
                    </strong>
                  </Link>
                </li>
                <li className="subnav__item">
                  <Link
                    to="/pharmacy-order-management/"
                    className="subnav-link"
                  >
                    <span className="subnav-link__illus subnav-link__illus--order">
                      <span className="illus__bg"></span>
                      <span className="illus__element illus__element1"></span>
                      <span className="illus__element illus__element2"></span>
                      <span className="illus__point illus__point1"></span>
                      <span className="illus__point illus__point2"></span>
                      <span className="illus__point illus__point3"></span>
                    </span>
                    <strong className="subnav-link__label">
                      Order Management
                    </strong>
                  </Link>
                </li>
                <li className="subnav__item">
                  <Link to="/DSCSA-compliance/" className="subnav-link">
                    <span className="subnav-link__illus subnav-link__illus--pedigree">
                      <span className="illus__bg"></span>
                      <span className="illus__point illus__point1"></span>
                      <span className="illus__point illus__point2"></span>
                      <span className="illus__point illus__point3"></span>
                    </span>
                    <strong className="subnav-link__label">
                      DSCSA - Pedigree
                    </strong>
                  </Link>
                </li>
                <li className="subnav__item">
                  <Link
                    to="/pharmacy-to-pharmacy-marketplace/"
                    className="subnav-link"
                  >
                    <span className="subnav-link__illus subnav-link__illus--p2p">
                      <span className="illus__bg"></span>
                      <span className="illus__point illus__point1"></span>
                      <span className="illus__point illus__point2"></span>
                      <span className="illus__point illus__point3"></span>
                    </span>
                    <strong className="subnav-link__label">
                      RPh2RPh -
                      <em className="p2p-item">
                        Pharmacy To Pharmacy Marketplace
                      </em>
                    </strong>
                  </Link>
                </li>
                <li className="subnav__item">
                  <Link to="/credit-card/" className="subnav-link">
                    <span className="subnav-link__illus subnav-link__illus--p2p">
                      <span className="illus__bg"></span>
                      <span className="illus__point illus__point1"></span>
                      <span className="illus__point illus__point2"></span>
                      <span className="illus__point illus__point3"></span>
                    </span>
                    <strong className="subnav-link__label">
                      Credit Card Advantage
                    </strong>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className={"navigation__item navigation__item--with-children"}>
            <button
              className="nav-link"
              onClick={() => showSubMenuCompany()}
              onMouseEnter={() => showSubMenuCompany()}
            >
              Company
            </button>
            <div
              className="subnav subnav--company"
              style={{ display: subMenuCompany ? "block" : "none" }}
            >
              <ul className="list-unstyled subnav__items">
                <li className="subnav__item subnav__item--about">
                  <a href="/about/" className="subnav-link">
                    About Us
                  </a>
                </li>
                <li className="subnav__item subnav__item--career">
                  <Link to="/careers/" className="subnav-link">
                    Careers
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="navigation__item">
            <Link to="/contact/" className="nav-link">
              Contact Us
            </Link>
          </li>
          <li className="navigation__item navigation__item--mobile">
            <a
              className="nav-link"
              rel="nofollow"
              href="https://app.ezrirx.com/login/"
            >
              Sign In
            </a>
          </li>
          <li className="navigation__item navigation__item--mobile">
            <p>
              Don’t have an account yet?{" "}
              <a rel="nofollow" href="https://app.ezrirx.com/signup/">
                Sign Up
              </a>
            </p>
          </li>
        </ul>
      </div>
    </>
  )
}
