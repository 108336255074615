import React from "react"
import Carousel from "react-bootstrap/Carousel"

const PromoMessage = () => {
  const promoMessages = [
    {
      href: "/credit-card/",
      content: (
        <>
          New: Find the right credit card for all your pharmacy needs!&nbsp;
          <strong>Spend smarter, save more!</strong>
        </>
      ),
      className: "credit-card",
    },
    {
      href: "/pharmacy-to-pharmacy-marketplace/",
      content: (
        <>
          New:&nbsp;<strong>RPh2RPh - Pharmacy-to-Pharmacy Marketplace!</strong>
        </>
      ),
      className: "p2p",
    },
  ]
  return (
    <>
      <div className="container-promo-message">
        <Carousel interval={10000} indicators={false} fade>
          {promoMessages.map((item, index) => (
            <Carousel.Item key={index}>
              <div className={`banner ${item.className}`}>
                <a rel="noreferrer" href={item.href} target="_blank">
                  {item.content}
                </a>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default PromoMessage
