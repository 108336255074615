import React, { useState, useEffect } from "react"
import NavMenu from "./NavMenu"
import MobileNavMenu from "./MobileNavMenu"
import { Link } from "gatsby"
import PromoMessage from "./PromoMessage"

const Header = props => {
  const [headerBg, setHeaderBg] = useState(
    (props.headerBg && props.headerBg) || false
  )

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        setHeaderBg(
          window.pageYOffset !== 0 &&
            window.pageYOffset >=
              document.getElementsByClassName("body")[0].offsetTop
        )
      },
      { passive: true }
    )
  }, [])

  return (
    <>
      <div className={`header ${headerBg ? "header-bg" : ""}`}>
        <PromoMessage />
        <div className="contents clearfix">
          <div className="logo">
            <Link to="/" className="text-hide">
              Ezrirx
            </Link>
          </div>
          <a href="tel:718-502-6610" className="telephone" rel="nofollow">
            (718) 502-6610
          </a>
          <MobileNavMenu />
          <NavMenu />
          <div className="join-us-box">
            <a
              className="button trans small"
              rel="nofollow"
              href="https://app.ezrirx.com/login/"
            >
              Sign In
            </a>
            <a
              className="button pink small"
              rel="nofollow"
              href="https://app.ezrirx.com/signup/pharmacy/"
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
export default Header
